<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <div class="content-main">
          <div style="padding-bottom: 20px;display: flex;justify-content: space-between">
            <div>
              <span>驾驶员：</span>
              <span>{{driverName}}</span>
            </div>
            <el-button @click="$router.go(-1)">返 回</el-button>
          </div>
        <ayl-table :table="table">
        </ayl-table>
      </div>
    </div>
    <el-dialog
    title="编辑"
    :visible.sync="centerDialogVisible"
    width="900px"
    center>
    <div class="content-box">
      <div class="content-main" style="-webkit-box-shadow: none;box-shadow: none;border-radius: 4px;border: none;">
        <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="交易类型" :label-width="formLabelWidth">
                <el-input v-model="form.tradeType" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="应付金额" :label-width="formLabelWidth">
                <el-input v-model="form.payableAmount" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="已付金额" :label-width="formLabelWidth">
                <el-input v-model="form.payedAmount" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="应付余额" :label-width="formLabelWidth">
                <el-input v-model="form.payableBalance" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="交易日期" :label-width="formLabelWidth" prop="settlementDate">
                <el-date-picker
                v-model="form.settlementDate"
                type="date"
                autocomplete="off"
                value-format="timestamp"
                placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="交易金额" :label-width="formLabelWidth" prop="tradeAmount">
                <el-input v-model="form.tradeAmount" autocomplete="off" placeholder="请输入" maxlength="32" type="number"></el-input>
            </el-form-item>
            <el-form-item label="结算方式" :label-width="formLabelWidth" prop="settlementForm">
                <el-select v-model="form.settlementForm" placeholder="请选择" value-key="id" @change='changeSelect'>
                <el-option v-for="(item, idx) in settlementNewData"
                            :key="idx"
                            :label="item.label"
                            :value="item.value"/>
                </el-select>
            </el-form-item>
            <el-form-item label="对方账号" :label-width="formLabelWidth" prop="partnerAcc">
                <el-input v-model="form.partnerAcc" autocomplete="off" placeholder="请输入" maxlength="32"></el-input>
            </el-form-item>
            <el-form-item label="对方户名" :label-width="formLabelWidth" prop="partnerAccName">
                <el-input v-model="form.partnerAccName" autocomplete="off" placeholder="请输入" maxlength="32"></el-input>
            </el-form-item>
            <el-form-item label="对方开户银行" :label-width="formLabelWidth" prop="partnerAccBank">
                <el-input v-model="form.partnerAccBank" autocomplete="off" placeholder="请输入" maxlength="32"></el-input>
            </el-form-item>
            <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input v-model="form.settlementRemark" autocomplete="off" placeholder="请输入(选填)" maxlength="64"></el-input>
            </el-form-item>
        </el-form>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm" :loading="submitLoading">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        centerDialogVisible: false,
        submitLoading: false,
        settlementNewData: [],       //结算方式列表
        settlementId: null,
        settlementStatus: null,
        form: {
          accountCheckingId: null,          //对账单编号
          tradeType: '应付',                //交易类型
          payableAmount: null,         //应付金额
          payedAmount: null,            //已付金额
          payableBalance: null,        //应付余额
          settlementDate: null,           //交易日期
          tradeAmount: null,              //交易金额
          settlementForm: null,           //结算方式
          companyAcc: null,               //账户户名--账户账号
          partnerAcc: null,               //对方账户
          partnerAccName: null,           //对方户名
          partnerAccBank: null,           //对方开户银行
          settlementRemark: null,         //备注
        },
        rules: {
            settlementDate: [{required: true, message: '请选择交易日期', trigger: 'change'}],
            tradeAmount: [{max: 36, required: true, message: '请输入交易金额', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
            settlementForm: {required: true, message: '请选择结算方式', trigger: 'change'},
            partnerAcc: [{max: 32, required: true, message: '请输入对方账号', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
            partnerAccName: [{max: 32, required: true, message: '请输入对方户名', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
            partnerAccBank: [{max: 32, required: true, message: '请输入对方开户银行', trigger: 'blur'},{validator: this.$validator.isContainBlank}],
        },
        formLabelWidth: '150px',
        nav: [{name: "出纳结算", path: ""}, {name: "外请车结算", path: "/cashier-settlement/owner-outside"}, {name: "结算明细", path: ""}],
        driverName: this.$route.query.driverName,
        table: {
          api: vm.$api.listDetailByCheckingId,
          query: {
           accountCheckingId: this.$route.query.accountCheckingId,
          },
          columns: [
            //   {
            //   title: '序号',
            //   width: '50px',
            //   $index: 'index',
            // },
            {
              title: '公司账户',
              key: 'companyAcc',
            }, {
              title: '对方账户',
              key: 'partnerAcc',
            }, {
              title: '对方户名',
              width: '150px',
              key: 'partnerAccName',
            }, {
              title: '对方开户银行',
              width: '180px',
              key: 'partnerAccBank',
            }, {
              title: '交易日期',
              width: '120px',
              filter: 'str2ymd',
              key: 'settlementDate',
            }, {
              title: '交易金额',
              width: '120px',
              key: 'tradeAmount',
            }, {
              title: '交易类型',
              width: '100px',
              key: 'tradeType',
            }, {
              title: '结算方式',
              width: '120px',
              key: 'settlementForm',
            }, {
              title: '出纳员',
              width: '150px',
              key: 'nickName',
            }, {
              title: '操作时间',
              width: '120px',
              filter: 'str2ymd',
              key: 'operationTime',
            }, {
              title: '操作',
              width: '155px',
              render (h, ctx) {
                return h('span', [
                  h('span', {
                    class: {
                      'table-view': vm.settlementStatus ==='已结清' ? false : true,
                      'table-class': vm.settlementStatus ==='已结清' ? true : false,
                    },
                    on: {
                      click: vm.settlementStatus ==='已结清' ? vm.goNull : vm.addUnit.bind(this, ctx.row)
                    }
                  }, '编辑'),
                  h('span', {
                    class: {
                      'table-delete': vm.settlementStatus ==='已结清' ? false : true,
                    },
                    on: {
                      click: vm.settlementStatus ==='已结清' ? vm.goNull : vm.delete.bind(this, ctx.row)
                    }
                  }, '删除')
                ])
              }
            }]
        }
      }
    },
    methods: {
        async delete(val){
            await this.$confirm(`您确认删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            await this.$api.deleteDetailByDetailId({
                settlementId: val.settlementId
            })
            this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
            })
            await this.$search(this.table)
        },
        changeSelect(val) {
            let obj = {};
            obj = this.settlementNewData.find((item)=>{
                return item.lable === val;
            });
            this.form.companyAcc = obj.accId;
        },
        async addUnit(val){
          this.settlementId = val.settlementId
          this.form.accountCheckingId = val.accountCheckingId
          let byAccountData = await this.$api.getAccountCheckingInfoByAccountCheckingId({
              accountCheckingId: val.accountCheckingId,
          })
          this.centerDialogVisible = true
          this.form.payableAmount = byAccountData.payableAmount,
          this.form.payedAmount = byAccountData.payedAmount,
          this.form.payableBalance = byAccountData.payableBalance

          this.settlementNewData = [] 
          let settlementData = await this.$api.settlementList({})
          for(let i =0; i<settlementData.length;i++){
              this.settlementNewData.push({
                  lable: settlementData[i].accName,
                  value: settlementData[i].accName,
                  accId: settlementData[i].accId,
              })
          }

          let xqData = await this.$api.getSettlementDetailInfoById({
              settlementId: val.settlementId
          })
            this.form.settlementDate = xqData.settlementDate,
            this.form.tradeAmount = (xqData.tradeAmount).toString(),
            this.form.settlementForm = xqData.settlementForm,
            this.form.partnerAcc = xqData.partnerAcc,
            this.form.companyAcc = xqData.companyAcc,
            this.form.partnerAccName = xqData.partnerAccName,
            this.form.partnerAccBank = xqData.settlementDate = xqData.partnerAccBank,
            this.form.settlementRemark = xqData.settlementRemark
      },

      //修改明细
      async addForm(){
          // if(parseFloat(this.form.payableBalance) < parseFloat(this.form.tradeAmount)){
          //   this.$message.error('交易金额应小于等于应付余额');
          //   return
          // }
          await this.$api.updateDetailByDetailId({
              steelSettlementDetailInfoVO: {
                  settlementId: this.settlementId,
                  accountCheckingId: this.form.accountCheckingId,
                  settlementType: '外请车结算',
                  tradeType: this.form.tradeType,
                  companyAcc: this.form.companyAcc,
                  settlementDate: this.form.settlementDate,
                  tradeAmount: this.form.tradeAmount,
                  settlementForm: this.form.settlementForm,
                  partnerAcc: this.form.partnerAcc,
                  partnerAccName: this.form.partnerAccName,
                  partnerAccBank: this.form.partnerAccBank,
                  settlementRemark: this.form.settlementRemark,
              }
          })
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success'
          });
          this.centerDialogVisible = false
          await this.$search(this.table)
      },
      submitForm() {
        this.$refs.form.validate(async valid => {
          if (!valid) return
          this.submitLoading = true
          try {
            this.addForm()
          } catch (e){}
          this.submitLoading = false
        })
      },
      goNull(){},
    },
    beforeRouteLeave (to, from, next) {
      to.meta.keepAlive = true 
      next()
    },
    async mounted () {
      // await this.$search(this.table)
      this.settlementStatus = this.$route.query.settlementStatus
    }
  }
</script>

<style lang='sass' scoped>
.content-box /deep/ .el-form-item
    width: 41%
    display: inline-block

/deep/ .el-dialog__header
    border-bottom: 1px solid $grayShallow3

/deep/.el-date-editor.el-input
    width: 160px
</style>
